<template>
  <div class="box-items">
    <div class="box-body">
      <div class="box-info">
        <my-upload field="img"
                   @crop-success="cropSuccess"
                   :modelValue.sync="show"
                   :width="300"
                   :height="300"
                   :no-square="true"
                   lang-type="en"
                   :withCredentials="true"
                   img-format="png"/>
        <img class="hand" @click="toggleShow" :src="getAvator(userinfo.Avator)" alt="">
        <div class="box-name">{{ this.$store.getters.name }}</div>
        <div class="box-item" :class="{ active: current === 3 }" @click="handleTabItem(3)">BTC Message</div>
        <div class="box-item" :class="{ active: current === 0 }" @click="handleTabItem(0)">My Company Information</div>
        <div class="box-item" :class="{ active: current === 1 }" @click="handleTabItem(1)">Change My Password</div>
        <div class="box-item" :class="{ active: current === 2 }" @click="handleTabItem(2)">My Favorites</div>
      </div>
      <company-info v-if="current === 0"/>
      <change-pwd v-if="current === 1"/>
      <favorite v-if="current === 2"/>
      <message v-if="current === 3"/>
    </div>
  </div>
</template>

<script>
import MyUpload from 'vue-image-crop-upload'
import CompanyInfo from './components/company-info'
import ChangePwd from './components/change-pwd'
import Favorite from './components/favorite'
import Message from './components/message'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'Account',
  components: {
    MyUpload,
    CompanyInfo,
    ChangePwd,
    Message,
    Favorite
  },
  data () {
    return {
      current: 3,
      userinfo: {},
      show: false
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    handleTabItem (index) {
      this.current = index
    },
    getUserInfo () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetFrontUserInfoByUserID', {
        UserID: getLocalStore().UserID
      }).then(() => {
        this.userinfo = this.$store.getters.userow
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getAvator (path) {
      return path ? process.env.VUE_APP_STATIC + path : require('@/assets/image/login/sidebar-default.png')
    },

    toggleShow () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl) {
      const file = this.dataURLtoFile(imgDataUrl, 'portrait.png')
      this.uploadImg(file)
    },
    // 上传头像
    uploadImg (file) {
      this.$store.dispatch('UploadAvator', {
        Files: file,
        UserID: getLocalStore().UserID
      }).then(() => {
        this.getUserInfo()
      }).catch((err) => {
        console.log(JSON.stringify(err))
      })
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转换成file对象
      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style scoped>
.box-items{
  background-color: #F5F5F5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-body{
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.box-info{
  width: 210px;
  height: 836px;
  /*border-radius: 20px;*/
  box-shadow: 1px 1px 1px #e1e1e1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
}
.box-info > img{
  width: 71px;
  height: 71px;
  margin-top: 80px;
  border-radius: 71px;
}
.box-name{
  font-size: 14px;
  font-weight: bold;
  color: #014479;
  margin: 10px 0 80px 0;
}
.box-item{
  width: 210px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  color: #535353;
  font-size: 14px;
}

.active{
  color: #ffffff;
  background-color: #014479;
}
</style>
